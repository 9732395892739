import React from "react";

import {
  Box,
  Text,
  Image,
} from "@chakra-ui/react";

const HomeCard = ({part_num}) => {
  return (
    <Box border='1px' borderColor='gray.100' w='250px' m={3} rounded='lg' overflow='hidden'>
      <Box>
        <Image
          src={`https://tnm-files.fra1.digitaloceanspaces.com/cgn/catalogue/${part_num}.PNG`}/>
      </Box>
      <Box bgColor='teal.500' h={["35px", "35px", "45px", "45px"]} d='flex'
           justifyContent='center' alignItems='center'
           fontSize={["13px", "13px", "15px", "15px"]}
           fontWeight={["semibold", "semibold", "bold", "bold"]}>
        <Text color='white'>{part_num}</Text>
      </Box>
    </Box>
  );
};

export default HomeCard;