import { Box, Icon, Spacer, Stack, Text } from '@chakra-ui/react';
import { ImLocation } from 'react-icons/im';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import React from 'react';

const HomeFooter = () => {
  return (
    <Stack
      direction={['column', 'column', 'row', 'row']}
      d="flex"
      alignItems="end"
      justifyContent="space-between"
      mt={5}
      bgColor="teal.500"
      h="100%"
      padding={4}
      color="white"
      fontWeight="semibold"
    >
      <Box w={['100%', '100%', '55%', '55%']}>
        <Text>
          CGN is a company specialized in all kinds of bearings. With a
          footprint in many countries, we are strategically located in Dubai,
          UAE to cater to the world. We’re focused on building strong and
          long-term relationships to the highest ethical standards. Our
          objective is to bring more values than expected in the midst of new
          changes now taking place globally.
        </Text>
      </Box>
      <Spacer />
      <Box
        w={['100%', '100%', '45%', '45%']}
        fontSize={['12px', '12px', '12px', '15px']}
      >
        <Text>
          <Icon as={ImLocation} mr={2} />
          Deira, Dubai, UAE
        </Text>
        <Text mt={2}>
          <EmailIcon mr={2} />
          sales@cgnparts.com
        </Text>
        <Text mt={2}>
          <PhoneIcon mr={2} /> +971 56 959 82 86
        </Text>
      </Box>
    </Stack>
  );
};

export default HomeFooter;
