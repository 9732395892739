import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  theme,
  Image,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import HomeCard from './components/HomeCard';
import HomeFooter from './components/HomeFooter';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box>
        {/*LOGO*/}
        <Box d="flex" justifyContent="center" m={6}>
          <Image src="https://tnm-files.fra1.digitaloceanspaces.com/cgn/pics/logo.png" />
        </Box>
        {/*SEARCH BAR*/}
        <Box d="flex" justifyContent="center" m={6}>
          <InputGroup size="md" w="3xl">
            <Input
              _placeholder={{ color: 'gray.400', fontWeight: 'semibold' }}
              placeholder="Search part number & Model"
              color="grey"
            />
            <InputRightElement children={<SearchIcon color="teal" />} />
          </InputGroup>
        </Box>
        {/*FEATURED TEXT*/}
        <Box d="flex" justifyContent="center" m={3}>
          <Text
            color="gray.400"
            fontSize={['35px', '35px', '50px', '50px']}
            fontWeight="bold"
          >
            Featured Products
          </Text>
        </Box>
        {/*CARDS*/}
        <Box d="flex" justifyContent="center" m={1}>
          <HomeCard part_num="CG0015" />
          <HomeCard part_num="CG0022" />
          <HomeCard part_num="CG0003" />
          <HomeCard part_num="CG0099" />
        </Box>
        <Box d="flex" justifyContent="center" m={2}>
          <HomeCard part_num="CG0045" />
          <HomeCard part_num="CG0032" />
          <HomeCard part_num="CG0024" />
          <HomeCard part_num="CG0040" />
        </Box>
        {/*FOOTER*/}
        <HomeFooter />
      </Box>
    </ChakraProvider>
  );
}

export default App;
